<template>
  <router-view/>
</template>

<style lang="scss">
// #app {
//   font-family: Avenir, Helvetica, Arial, sans-serif;
//   -webkit-font-smoothing: antialiased;
//   -moz-osx-font-smoothing: grayscale;
//   color: #2c3e50;
// }
* {
  margin: 0;
  padding: 0;
}
div::-webkit-scrollbar {
  display: none;
}
</style>
