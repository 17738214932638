import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

createApp(App).use(store).use(router).mount('#app')

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCVsUNveEEctwvBGjki1DGiyOrKMNSMUCc",
  authDomain: "light-games-f5240.firebaseapp.com",
  projectId: "light-games-f5240",
  storageBucket: "light-games-f5240.appspot.com",
  messagingSenderId: "406205684094",
  appId: "1:406205684094:web:42d136c987aaf3ce6d7b2f",
  measurementId: "G-6FBPH7XG0V"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);