import { createRouter, createWebHashHistory, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import Privacy from '../views/Privacy.vue'

const routes = [
  {
    path: '/',
    component: HomeView
  },
  {
    path: '/privacy',
    component: Privacy
  }
]

const router = createRouter({
  history: createWebHistory('/'),
  routes,
  scrollBehavior(to, from, savedPosition) {
    // always scroll to top
    return { top: 0 }
  },
})

export default router
