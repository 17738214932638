<template>
    <div class="content">
    <p style="margin:0in;text-align:center;font-size:14px;font-family:DengXian;">Privacy Policy</p>
    <p style="margin:0in;text-align:justify;font-size:14px;font-family:DengXian;">&nbsp;</p>
    <p style="margin:0in;text-align:justify;font-size:14px;font-family:DengXian;">Last Updated: 2024-03-27 &nbsp;</p>
    <p style="margin:0in;text-align:justify;font-size:14px;font-family:DengXian;">&nbsp;</p>
    <p style="margin:0in;text-align:justify;font-size:14px;font-family:DengXian;">This Privacy Policy is intended to help users understand how "Light Games" (referred to herein as "we", "our", or "the App") collects, uses, and shares your personal information as well as your rights regarding your personal information</p>
    <p style="margin:0in;text-align:justify;font-size:14px;font-family:DengXian;">&nbsp;</p>
    <p style="margin:0in;text-align:justify;font-size:14px;font-family:DengXian;">Please read our Privacy Policy carefully. By using our services, you acknowledge and agree to the terms of this Privacy Policy. If you do not agree with any part of this Privacy Policy, please do not use "Light Games".</p>
    <p style="margin:0in;text-align:justify;font-size:14px;font-family:DengXian;">&nbsp;</p>
    <p style="margin:0in;text-align:justify;font-size:14px;font-family:DengXian;">1.Information Collection</p>
    <p style="margin:0in;text-align:justify;font-size:14px;font-family:DengXian;">&nbsp;</p>
    <p style="margin:0in;text-align:justify;font-size:14px;font-family:DengXian;">We may collect the following types of information:</p>
    <p style="margin:0in;text-align:justify;font-size:14px;font-family:DengXian;">&nbsp;</p>
    <p style="margin:0in;text-align:justify;font-size:14px;font-family:DengXian;">- Device Information: Including but not limited to operating system version, device type, unique device identifiers (such as Google Advertising ID), and network information.</p>
    <p style="margin:0in;text-align:justify;font-size:14px;font-family:DengXian;">&nbsp;</p>
    <p style="margin:0in;text-align:justify;font-size:14px;font-family:DengXian;">- Usage Information: Information regarding your use of the app, such as game progress, achievements, and interactions with the service.</p>
    <p style="margin:0in;text-align:justify;font-size:14px;font-family:DengXian;">&nbsp;</p>
    <p style="margin:0in;text-align:justify;font-size:14px;font-family:DengXian;">- Ad Data: We may use third-party advertising partners to display ads, who may collect and use information regarding ad performance and your interaction with ads.</p>
    <p style="margin:0in;text-align:justify;font-size:14px;font-family:DengXian;">&nbsp;</p>
    <p style="margin:0in;text-align:justify;font-size:14px;font-family:DengXian;">2.How We Use Information</p>
    <p style="margin:0in;text-align:justify;font-size:14px;font-family:DengXian;">&nbsp;</p>
    <p style="margin:0in;text-align:justify;font-size:14px;font-family:DengXian;">We use the information collected to:</p>
    <p style="margin:0in;text-align:justify;font-size:14px;font-family:DengXian;">&nbsp;</p>
    <p style="margin:0in;text-align:justify;font-size:14px;font-family:DengXian;">- Provide, maintain, and improve our services.</p>
    <p style="margin:0in;text-align:justify;font-size:14px;font-family:DengXian;">&nbsp;</p>
    <p style="margin:0in;text-align:justify;font-size:14px;font-family:DengXian;">- Display personalized ads and content.</p>
    <p style="margin:0in;text-align:justify;font-size:14px;font-family:DengXian;">&nbsp;</p>
    <p style="margin:0in;text-align:justify;font-size:14px;font-family:DengXian;">- Track the performance of ads.</p>
    <p style="margin:0in;text-align:justify;font-size:14px;font-family:DengXian;">&nbsp;</p>
    <p style="margin:0in;text-align:justify;font-size:14px;font-family:DengXian;">- Analyze and monitor usage of the app and activities.</p>
    <p style="margin:0in;text-align:justify;font-size:14px;font-family:DengXian;">&nbsp;</p>
    <p style="margin:0in;text-align:justify;font-size:14px;font-family:DengXian;">- Comply with applicable laws, regulations, or requests.</p>
    <p style="margin:0in;text-align:justify;font-size:14px;font-family:DengXian;">&nbsp;</p>
    <p style="margin:0in;text-align:justify;font-size:14px;font-family:DengXian;">3.Sharing Information</p>
    <p style="margin:0in;text-align:justify;font-size:14px;font-family:DengXian;">&nbsp;</p>
    <p style="margin:0in;text-align:justify;font-size:14px;font-family:DengXian;">We do not share personal information with any third parties outside of our company except in the following circumstances:</p>
    <p style="margin:0in;text-align:justify;font-size:14px;font-family:DengXian;">&nbsp;</p>
    <p style="margin:0in;text-align:justify;font-size:14px;font-family:DengXian;">- Advertising Partners: For ad targeting and optimization.</p>
    <p style="margin:0in;text-align:justify;font-size:14px;font-family:DengXian;">&nbsp;</p>
    <p style="margin:0in;text-align:justify;font-size:14px;font-family:DengXian;">- Legal Requirements: Your information may be shared if we believe it is necessary to comply with applicable laws, court orders, or to follow legal process.</p>
    <p style="margin:0in;text-align:justify;font-size:14px;font-family:DengXian;">&nbsp;</p>
    <p style="margin:0in;text-align:justify;font-size:14px;font-family:DengXian;">- Mergers or Sales: If we are involved in a merger, asset sale, financing, or acquisition of all or a portion of our business, your information may be transferred to a third party.</p>
    <p style="margin:0in;text-align:justify;font-size:14px;font-family:DengXian;">&nbsp;</p>
    <p style="margin:0in;text-align:justify;font-size:14px;font-family:DengXian;">4.User Rights</p>
    <p style="margin:0in;text-align:justify;font-size:14px;font-family:DengXian;">&nbsp;</p>
    <p style="margin:0in;text-align:justify;font-size:14px;font-family:DengXian;">Users have the right to:</p>
    <p style="margin:0in;text-align:justify;font-size:14px;font-family:DengXian;">&nbsp;</p>
    <p style="margin:0in;text-align:justify;font-size:14px;font-family:DengXian;">- Access, correct, update, or request the deletion of personal information.</p>
    <p style="margin:0in;text-align:justify;font-size:14px;font-family:DengXian;">&nbsp;</p>
    <p style="margin:0in;text-align:justify;font-size:14px;font-family:DengXian;">- Object to certain forms of information processing.</p>
    <p style="margin:0in;text-align:justify;font-size:14px;font-family:DengXian;">&nbsp;</p>
    <p style="margin:0in;text-align:justify;font-size:14px;font-family:DengXian;">- Request restrictions or oppose our processing of your personal information, in some cases for legitimate business interests.</p>
    <p style="margin:0in;text-align:justify;font-size:14px;font-family:DengXian;">&nbsp;</p>
    <p style="margin:0in;text-align:justify;font-size:14px;font-family:DengXian;">These rights are subject to certain legal restrictions.</p>
    <p style="margin:0in;text-align:justify;font-size:14px;font-family:DengXian;">&nbsp;</p>
    <p style="margin:0in;text-align:justify;font-size:14px;font-family:DengXian;">5.Children's Privacy</p>
    <p style="margin:0in;text-align:justify;font-size:14px;font-family:DengXian;">&nbsp;</p>
    <p style="margin:0in;text-align:justify;font-size:14px;font-family:DengXian;">"Light Games" is not intended for children under the age of 13. If we become aware that we have collected personal information from a child under the age of 13, we will take steps to delete such information.</p>
    <p style="margin:0in;text-align:justify;font-size:14px;font-family:DengXian;">&nbsp;</p>
    <p style="margin:0in;text-align:justify;font-size:14px;font-family:DengXian;">6.Security</p>
    <p style="margin:0in;text-align:justify;font-size:14px;font-family:DengXian;">&nbsp;</p>
    <p style="margin:0in;text-align:justify;font-size:14px;font-family:DengXian;">We take reasonable measures to ensure the security of your personal information, but please note that no internet transmission is 100% secure.</p>
    <p style="margin:0in;text-align:justify;font-size:14px;font-family:DengXian;">&nbsp;</p>
    <p style="margin:0in;text-align:justify;font-size:14px;font-family:DengXian;">7.Changes to Our Privacy Policy</p>
    <p style="margin:0in;text-align:justify;font-size:14px;font-family:DengXian;">&nbsp;</p>
    <p style="margin:0in;text-align:justify;font-size:14px;font-family:DengXian;">We may update our Privacy Policy from time to time. If we make significant changes, we will notify you through the "Light Games" app or through other means, such as by email.</p>
    <p style="margin:0in;text-align:justify;font-size:14px;font-family:DengXian;">&nbsp;</p>
    <p style="margin:0in;text-align:justify;font-size:14px;font-family:DengXian;">8.Contact Us</p>
    <p style="margin:0in;text-align:justify;font-size:14px;font-family:DengXian;">&nbsp;</p>
    <p style="margin:0in;text-align:justify;font-size:14px;font-family:DengXian;">If you have any questions or concerns about this Privacy Policy, please contact us through:</p>
    <p style="margin:0in;text-align:justify;font-size:14px;font-family:DengXian;">&nbsp;</p>
    <p style="margin:0in;text-align:justify;font-size:14px;font-family:DengXian;">Email: <a href="https://www.caitun.com">help@caitun.com</a></p>
    </div>
</template>
<script steup>
document.title = 'Privacy Policy'
</script>
<style scoped lang="scss">
.content {
    background-color: #ffffff;
    padding: 10px;
}
</style>