import axios from 'axios'

const api = axios.create()

api.interceptors.response.use(function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    return response
}, function (error) {
    return Promise.reject(error)
})

export default api
