<template>
  <div class="home">
    <div class="top-bg" :style="topBgTop" ></div>
    <div class="header">
      <img class="logo" src="http://dbp-resource.cdn.bcebos.com/21958a28-c22c-15bf-4c0d-96899a76388a/logo.png" alt="">
      <img class="ad-image" src="http://dbp-resource.cdn.bcebos.com/21958a28-c22c-15bf-4c0d-96899a76388a/img_netname.png" alt="">
    </div>
    <div class="c-box">
      <div class="z-div"></div>
      <div class="content" @scroll="scrollEvent">
        <div class="tab-bar">
          <img class="icon" src="http://dbp-resource.cdn.bcebos.com/21958a28-c22c-15bf-4c0d-96899a76388a/recommend_icon.png" alt="">
          <div class="tab-title">{{rText}}</div>
          <!-- <div class="load-more">{{lText}}</div> -->
        </div>
        <div class="recommend-box">
          <div style="display:flex;flex-direction:row;scrollbar">
            <div @click="jumpH5(item.url)" class="recommend-item" v-for="(item, index) in recommended" :key="item.ID">
              <img class="banner" :src="item.banner" alt="">
              <video v-if="index == 0 && item.video != ''" style="position:absolute;" :src="item.video" width="100%" :height="vHeight" muted="true" autoplay="autoplay"></video>
              <div class="name">{{item.name}}</div>
              <div class="desc">{{item.desc}}</div>
              <img class="icon" :src="item.icon" alt="">
            </div>
          </div>
        </div>
        <div class="tab-bar">
          <img class="icon" src="http://dbp-resource.cdn.bcebos.com/21958a28-c22c-15bf-4c0d-96899a76388a/hot_icon.png" alt="">
          <div class="tab-title">{{pText}}</div>
          <!-- <div class="load-more">{{lText}}</div> -->
        </div>
        <div class="popular-box">
          <div @click="jumpH5(item.url)" class="popular-item" v-for="item in popular" :key="item.ID">
            <img class="icon" :src="item.icon" alt="">
            <div class="mask"></div>
            <div class="name"><p class="name-text">{{item.name}}</p></div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script setup>
// @ is an alias to /src
import { ref } from 'vue'
import api from '../utils/api'
import { getAnalytics, logEvent } from "firebase/analytics";

document.title = 'Free Online Games on LightGames | Play Now!'
const setFontSize = () => {
  const htmlDom = document.getElementsByTagName('html')[0]
  let htmlWidth = document.documentElement.clientWidth || document.body.clientWidth
  var widtha = window.screen.width;
  var heightb= window.screen.height;
  if(widtha>heightb){
    htmlDom.style.fontSize = `${htmlWidth / 1280}px`
  }else{
    htmlDom.style.fontSize = `${htmlWidth / 720}px`
  }
}
window.onresize = setFontSize
setFontSize()

const popular = ref([])
const recommended = ref([])
const analytics = getAnalytics()
const rText = ref('Recommended')
const pText = ref('Popular')
const lText = ref('LOAD MORE')
let htmlWidth = document.documentElement.clientWidth || document.body.clientWidth
const vHeight = ref('')
var widtha = window.screen.width;
var heightb= window.screen.height;
if(widtha>heightb){
  vHeight.value = 270 * htmlWidth / 1280
}else{
  vHeight.value = 270 * htmlWidth / 720
}

var lan = navigator.language || navigator.browserLanguage
var cLanguage = 'English'
if (lan.indexOf('zh') > -1) {
  cLanguage = 'Chinese'
  rText.value = '推荐'
  pText.value = '流行'
  lText.value = '加载更多'
} else if (lan.indexOf('ru') > -1) {
  cLanguage = 'Russian'
  rText.value = 'рекомендуемые'
  pText.value = 'популярный'
  lText.value = 'ЗАГРУЗИ БОЛЬШЕ'
} else if (lan.indexOf('ja') > -1) {
  cLanguage = 'Japanese'
  rText.value = '推奨'
  pText.value = '人気のある'
  lText.value = 'もっと読み込む'
} else if (lan.indexOf('ko') > -1) {
  cLanguage = 'Korean'
  rText.value = '추천'
  pText.value = '인기 있는'
  lText.value = '더 많이 로드하세요'
} else if (lan.indexOf('fr') > -1) {
  cLanguage = 'French'
  rText.value = 'Recommandé'
  pText.value = 'Populaire'
  lText.value = 'CHARGER PLUS'
}
api.get('/api/lightgames/list?language=' + lan).then(res => {
  popular.value = [...res.data.data.popular]
  recommended.value = [...res.data.data.recommended]
})

var topBgTop = ref('top:0px')
function scrollEvent(e) {
  topBgTop.value = `top:-${event.target.scrollTop}px`
}

function jumpH5(url) {
  console.log(url)
  logEvent(analytics, 'h5_jump_game', { url: url })
  window.location.href = url
}

</script>
<style scoped lang="scss">
.home {
  width: 100vw;
  max-height: 100vh;
  background-color: #F0F3F7;
}
.c-box {
  width: 100vw;
  height: 100vh;
  display:flex;
  flex-direction: column;
}
.content {
  flex: 1;
  overflow-y: scroll;
  position: relative;
  padding-top: 48rem;
}
img {
  object-fit: cover;
  object-position: center;
}
@media screen and (orientation:landscape) {
.top-bg {
  width: 100%;
  height: 1340rem;
  position: absolute;
  left: 0;
  top: 0;
  background-image: url('http://dbp-resource.cdn.bcebos.com/21958a28-c22c-15bf-4c0d-96899a76388a/%E7%BB%84%2023%20%281%29-2.png');
  background-size: 100% 100%;
}
.header {
  width: 100%;
  height: 100rem;
  position: fixed;
  top: 0;
  z-index: 10;
  background-image: url('http://dbp-resource.cdn.bcebos.com/21958a28-c22c-15bf-4c0d-96899a76388a/20240329-095537.png');
  background-size: 100% 100%;
  .logo {
    width: 200rem;
    height: 100rem;
    position: absolute;
    left: 30rem;
    top: 0;
  }
  .ad-image {
    width: 313rem;
    height: 46rem;
    position: absolute;
    right: 60rem;
    top: 28rem;
  }
}
.z-div {
  height:100rem;
  width:100%;
}
.tab-bar {
  width: 100%;
  height: 44rem;
  padding-left: 60rem;
  padding-right: 60rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
  .icon {
    width: 44rem;
    height: 44rem;
    margin-right: 12rem;
  }
  .tab-title {
    flex: 1;
    font-size: 28rem;
    color: #0E1739;
    font-weight: bold;
  }
  .load-more {
    font-size: 24rem;
    color: #0E1739;
    opacity: 0.75;
  }
}
.recommend-box {
  width: 100%;
  padding-left: 60rem;
  overflow-x: scroll;
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  .recommend-item {
    margin-top: 22rem;
    margin-bottom: 42rem;
    width: 480rem;
    height: 368rem;
    margin-right: 40rem;
    overflow: hidden;
    border-radius: 24rem;
    position: relative;
    background-color: #FFFFFF;
    cursor: pointer;
    transition: transform 0.3s ease;
    .banner {
      width: 100%;
      height: 270rem;
      position: absolute;
      left: 0;
      top: 0;
    }
    .name {
      position: absolute;
      left: 140rem;
      top: 280rem;
      font-size: 28rem;
      color: #3D3D3D;
      font-weight: 500;
      width: 310rem;
      max-lines: 1;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
    .desc {
      position: absolute;
      left: 140rem;
      top: 320rem;
      font-size: 20rem;
      color: #3D3D3D;
      opacity: 0.5;
      font-weight: 500;
      width: 310rem;
      -webkit-line-clamp: 1;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
    .icon {
      position: absolute;
      left: 20rem;
      top: 252rem;
      width: 96rem;
      height: 96rem;
      border-radius: 16rem;
      opacity: 1;
      border: 4rem solid #FFFFFF;
      box-shadow: 0 2rem 2rem 0 #999999;
    }
  }
  .recommend-item:hover{
    transform: scale(1.1);
  }
}
.popular-box {
  width: 100%;
  padding-left: 60rem;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  .popular-item {
    margin-top: 22rem;
    margin-bottom: 40rem;
    width: 260rem;
    height: 260rem;
    margin-right: 40rem;
    position: relative;
    border-radius: 24rem;
    overflow: hidden;
    cursor: pointer;
    transition: transform 0.3s ease;
    .icon {
      width: 100%;
      height: 100%;
      border-radius: 24rem;
    }
    .mask {
      width: 100%;
      height: 136rem;
      background: linear-gradient(180deg, rgba(0, 1, 47, 0) 0%, #00012F 100%);
      position: absolute;
      left: 0;
      bottom: 0;
    }
    .name {
      width: 220rem;
      height: 48rem;
      position: absolute;
      left: 20rem;
      top: 192rem;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .name-text {
      width: 100%;
      text-align: center;
      color: #ffffff;
      font-size: 24rem;
      opacity: 0.75;
      font-weight: 500;
      line-height: 24rem;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
  .popular-item:hover{
    transform: scale(1.1);
  }
}
  
}
@media screen and (orientation:portrait) {
.top-bg {
  width: 100%;
  height: 820rem;
  position: absolute;
  left: 0;
  top: 0;
  background-image: url('http://dbp-resource.cdn.bcebos.com/21958a28-c22c-15bf-4c0d-96899a76388a/%E7%BB%84%2023.png');
  background-size: 100% 100%;
}
.header {
  width: 100%;
  height: 158rem;
  position: fixed;
  top: 0;
  z-index: 10;
  background-image: url('http://dbp-resource.cdn.bcebos.com/21958a28-c22c-15bf-4c0d-96899a76388a/20240329-095531.png');
  background-size: 100% 100%;
  .logo {
    width: 200rem;
    height: 100rem;
    position: absolute;
    left: 9rem;
    top: 58rem;
  }
  .ad-image {
    width: 313rem;
    height: 46rem;
    position: absolute;
    right: 40rem;
    top: 85rem;
  }
}
.z-div {
  height:158rem;
  width:100%;
}
.tab-bar {
  width: 100%;
  height: 44rem;
  padding-left: 40rem;
  padding-right: 40rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
  .icon {
    width: 44rem;
    height: 44rem;
    margin-right: 12rem;
  }
  .tab-title {
    flex: 1;
    font-size: 28rem;
    color: #000000;
    font-weight: bold;
  }
  .load-more {
    font-size: 24rem;
    color: #000000;
    opacity: 0.75;
  }
}
.recommend-box {
  width: 100%;
  padding-left: 40rem;
  overflow-x: scroll;
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  .recommend-item {
    margin-top: 22rem;
    margin-bottom: 42rem;
    width: 480rem;
    height: 368rem;
    margin-right: 40rem;
    overflow: hidden;
    border-radius: 24rem;
    position: relative;
    background-color: #FFFFFF;
    cursor: pointer;
    transition: transform 0.3s ease;
    .banner {
      width: 100%;
      height: 270rem;
      position: absolute;
      left: 0;
      top: 0;
    }
    .name {
      position: absolute;
      left: 140rem;
      top: 280rem;
      font-size: 28rem;
      color: #3D3D3D;
      font-weight: 500;
      width: 310rem;
      max-lines: 1;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
    .desc {
      position: absolute;
      left: 140rem;
      top: 320rem;
      font-size: 20rem;
      color: #3D3D3D;
      opacity: 0.5;
      font-weight: 500;
      width: 310rem;
      -webkit-line-clamp: 1;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
    .icon {
      position: absolute;
      left: 20rem;
      top: 252rem;
      width: 96rem;
      height: 96rem;
      border-radius: 16rem;
      opacity: 1;
      border: 4rem solid #FFFFFF;
      box-shadow: 0 2rem 2rem 0 #999999;
    }
  }
  .recommend-item:hover{
    transform: scale(1.1);
  }
}
.popular-box {
  width: 100%;
  padding-left: 40rem;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  .popular-item {
    margin-top: 22rem;
    margin-bottom: 40rem;
    width: 192rem;
    height: 192rem;
    margin-right: 32rem;
    position: relative;
    border-radius: 24rem;
    overflow: hidden;
    cursor: pointer;
    transition: transform 0.3s ease;
    .icon {
      width: 100%;
      height: 100%;
      border-radius: 24rem;
    }
    .mask {
      width: 100%;
      height: 120rem;
      background: linear-gradient(180deg, rgba(0, 1, 47, 0) 0%, #00012F 100%);
      position: absolute;
      left: 0;
      bottom: 0;
    }
    .name {
      width: 172rem;
      height: 48rem;
      position: absolute;
      left: 10rem;
      top: 128rem;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .name-text {
      width: 100%;
      text-align: center;
      color: #ffffff;
      font-size: 24rem;
      opacity: 0.75;
      font-weight: 500;
      line-height: 24rem;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
  .popular-item:hover{
    transform: scale(1.1);
  }
}
}
</style>
